import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    documents: [],
    status: false,
    actualPropertyID: 0,
    actualAGS: '',
    company: {},
    property: {},
    docTypes: {},
    custProp: {},
    emp_type: '',
    pemp: {}
  },
  getters: {
    getPropDocs: state => {
      return state.documents
    },
    getObjStatus: state => {
      return state.status
    },
    getPropertyID: state => {
      return state.actualPropertyID
    },
    getActualAGS: state => {
      return state.actualAGS
    },
    getCompany: state => {
      return state.company
    },
    getProperty: state => {
      return state.property
    },
    getDocTypes: state => {
      return state.docTypes
    },
    getCustProp: state => {
      return state.custProp
    },
    getEmpType: state => {
      return state.emp_type
    },
    getPemp: state => {
      return state.pemp
    }
  },
  mutations: {
    PERSIST_DOCS (state, propDocs) {
      state.documents = propDocs
    },
    DELETE_DOCS (state) {
      state.documents = []
    },
    PERSIST_STATUS (state, status) {
      state.status = status
    },
    DELETE_STATUS (state, status) {
      state.status = status
    },
    PERSIST_PROP_ID (state, id) {
      state.actualPropertyID = id
    },
    PERSIST_AGS (state, ags) {
      state.actualAGS = ags
    },
    PERSIST_COMPANY (state, company) {
      state.company = company
    },
    PERSIST_PROP (state, property) {
      state.property = property
    },
    PERSIST_DOCTYPES (state, docTypes) {
      state.docTypes = docTypes
    },
    PERSIST_CUSTPROP (state, custProp) {
      state.custProp = custProp
    },
    PERSIST_EMP_TYPE (state, type) {
      state.emp_type = type
    },
    PERSIST_PICKED_EMP (state, emp) {
      state.pemp = emp
    }
  },
  actions: {
    SAVE_DOCS ({ commit }, propDocs) {
      commit('PERSIST_DOCS', propDocs)
      commit('PERSIST_STATUS', true)
    },
    REMOVE_DOCS ({ commit }) {
      commit('DELETE_DOCS')
      commit('DELETE_STATUS', false)
    },
    SAVE_PROP_ID ({ commit }, id) {
      commit('PERSIST_PROP_ID', id)
    },
    SAVE_AGS ({ commit }, ags) {
      commit('PERSIST_AGS', ags)
    },
    SAVE_COMPANY ({ commit }, company) {
      commit('PERSIST_COMPANY', company)
    },
    SAVE_DOCTYPES ({ commit }, docTypes) {
      commit('PERSIST_DOCTYPES', docTypes)
    },
    SAVE_CUSTPROP ({ commit }, custProp) {
      commit('PERSIST_CUSTPROP', custProp)
    },
    SAVE_EMP_TYPE ({ commit }, type) {
      commit('PERSIST_EMP_TYPE', type)
    },
    SAVE_PICKED_EMP ({ commit }, emp) {
      commit('PERSIST_PICKED_EMP', emp)
    }
  }
})
