<template>
  <transition
    v-if="showModal"
    name="modal-fade"
  >
    <div class="modal-backdrop">
      <div
        id="updateCustomerModal"
        class="modal update-cust"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                id="exampleModalLabel"
                class="modal-title"
              >
                Kunde bearbeiten
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="update-cust"
                aria-label="Close"
                @click="hideModal()"
              >
                <span
                  class="x-position"
                  aria-hidden="true"
                >&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="updateCustomer()">
                <div class="row">
                  <div class="col-sm-3">
                    <label>Anrede</label>
                    <select
                      v-model="customer.title"
                      class="form-control"
                      required
                    >
                      <option value="">
                        ---
                      </option>
                      <option value="Herr">
                        Herr
                      </option>
                      <option value="Frau">
                        Frau
                      </option>
                    </select>
                  </div>
									<div class="col-sm-2"></div>
									<div class="col-sm-6">
										<label>{{ company.short }}-Kundennummer</label>
                    <input
                      v-model="customer.customer_nr"
                      class="form-control"
                    />
									</div>
                </div>
                <div class="row">
                  <div class="col-sm-5">
                    <label>Vorname</label>
                    <input
                      v-model="customer.forename"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="col-sm-6">
                    <label>Nachname</label>
                    <input
                      v-model="customer.surname"
                      class="form-control"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="update-cust"
                aria-label="Close"
                @click="hideModal()"
              >
                Abbrechen
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="updateCustomer()"
              >
                Aktualisieren
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: ['customer', 'company'],
  data () {
    return {
      showModal: false
    }
	},
  methods: {
		updateCustomer () {
			this.$axios.put(process.env.VUE_APP_BASE_URI + 'customer/' + this.customer.id, {
				title: this.customer.title,
				forename: this.customer.forename,
				surname: this.customer.surname,
				customer_nr: this.customer.customer_nr
			})
				.then(() => {
					this.hideModal();
					this.$router.go();
				})
		},
    showUpdate () {
      this.showModal = true
      let element = document.getElementById("update-cust")
      $(element).modal('show')
    },
    hideModal () {
      this.showModal = false
      let element = document.getElementById("update-cust")
      $(element).modal('hide')
    }
	}
}
</script>
<style scoped>
.modal-dialog {
	height: 320px !important;
}
.modal-position {
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
}
.modal {
	padding-top: 0;
	height: 376px !important;
}
.x-position {
  position: relative;
  top: -11px;
  right: 6px;
}
</style>
