<template>
  <div class="wrapper login">
    <form
      id="formLogin"
      @submit.prevent="login"
    >
      <div class="logo"></div>
      <h1>Login</h1>
      <div v-if="error">
        {{ error }}
      </div>
      <div class="form-group">
        <div class="input-group">
          <label class="input-group-addon">Benutzername</label>
          <input
            v-model="formName"
            type="text"
            class="form-control"
            placeholder="Benutzername"
          >
        </div>
      </div>
      <div class="form-group">
        <div class="input-group">
          <label class="input-group-addon">Passwort</label>
          <input
            v-model="formPass"
            type="password"
            class="form-control"
            placeholder="******"
          >
        </div>
      </div>
      <div class="row">
        <button
          id="btnLogin"
          type="submit"
          class="btn btn-small btn-danger text-center"
        >
          Login
        </button>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  data () {
    return {
      formName: '',
      formPass: '',
      error: ''
    }
  },
  updated () {
    this.checkCurrentLogin()
  },
  created () {
    this.checkCurrentLogin()
  },
  methods: {
    login () {
      this.$axios.post(process.env.VUE_APP_BASE_URI + 'auth/login', {
        username: this.formName,
        password: this.formPass
      })
        .then((response) => {
          this.loginSuccessful(response)
        })
        .catch(() => this.loginFailed())
    },
    loginSuccessful (response) {
      if (!response.data.token) {
        this.loginFailed()
        return
      }
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('id', response.data.id)
      const token = localStorage.getItem('token')
      if (token) {
        this.$axios.defaults.headers.common['Content-Type'] = 'application/json'
        this.$axios.defaults.headers.common['GSDAuthorization'] = token
      }

      var exp = new Date()
      exp.setHours(exp.getHours() + 2)
      localStorage.setItem('exp', exp)
      // ! remove id 1
      if(response.data.id == 2 || response.data.id == 1){
        this.$router.replace(this.$route.query.redirect || '/admin')
      } else {
        this.$router.replace(this.$route.query.redirect || '/home')
      }
    },
    loginFailed () {
      this.error = 'Login fehlgeschlagen!'
      localStorage.removeItem('token')
      localStorage.removeItem('id')
    },
    checkCurrentLogin () {
      if (localStorage.getItem('token')) {
        // ! remove id 1
        if (localStorage.getItem('id') == 2 || localStorage.getItem('id') == 1){
          this.$router.replace(this.$route.query.redirect || '/admin')
        } else {
          this.$router.replace(this.$route.query.redirect || '/home')
        }
      }
    }
  }
}
</script>
