<template>
  <div
    v-if="empType !== ''"
    class="document-selection"
  >
    <p v-if="empType === 'internal'">
      Innendienst-Auswahl
    </p>
    <p v-if="empType === 'external'">
      Außendienst-Auswahl
    </p>
    <div class="row">
      <table class="table mRight table-ex">
        <thead class="table-header">
          <tr>
            <th
              class="table-header"
              width="70"
            >
              #
            </th>
            <th class="table-header">
              Benutzername
            </th>
            <th class="table-header">
              E-Mail
            </th>
            <th
              class="table-header"
              width="100"
            >
              Anrede
            </th>
            <th class="table-header">
              Vorname
            </th>
            <th class="table-header">
              Nachname
            </th>
            <th class="table-header">
              Erstellt am
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- TODO: add click, add picked class -->
          <tr
            v-for="employee in employeeList"
            :key="employee.updated_at"
            class="table-row"
            :class="{ picked: pickedEmployee.id === employee.id}"
            @click="pick(employee)"
          >
            <th width="70">
              {{ employee.id }}
            </th>
            <td>{{ employee.username }}</td>
            <td>{{ employee.email }}</td>
            <td width="80">
              {{ employee.title }}
            </td>
            <td>{{ employee.forename }}</td>
            <td>{{ employee.surname }}</td>
            <td>{{ employee.created_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      empType: '',
      employeeList: [],
      pickedEmployee: {}
    }
	},
  computed: {
    ...mapGetters(['getEmpType', 'getPemp'])
  },
  watch: {
    getEmpType (newValue) {
      this.empType = newValue
      this.pickedEmployee = {}
      this.loadEmployees()
    },
    pickedEmployee (newValue) {
      this.$store.dispatch('SAVE_PICKED_EMP', newValue)
    },
    getPemp (newValue,) {
      this.pickedEmployee = newValue
    }
  },
  mounted () {
    this.empType = 'internal'
    this.loadEmployees()
  },
  methods: {
    pick (employee) {
      this.pickedEmployee = employee
    },
    loadEmployees () {
      var uri = ''
      if(this.empType === 'internal') {
        uri = 'admin/showInternal'
      } else if (this.empType === 'external') {
        uri = 'employee/show'
      }
      this.$axios.post(process.env.VUE_APP_BASE_URI + uri)
        .then((response) => {
          this.employeeList = response.data
        })
    }
  }
}
</script>
<style>
.mar-bot {
  margin-bottom: 1%;
}
.table-ex {
  margin-right: 1%;
  width: unset;
  position: sticky;
  margin-bottom: 320px;
}
tr {
width: 100%;
display: inline-table;
table-layout: fixed;
}
table{
 display: -moz-groupbox;
}
tbody{
  overflow-y: scroll;
  height: 300px;
  width: 100%;
  position: absolute;
}
</style>
