<template>
  <div>
    <form
      class="row header"
      name="header_form"
      @submit.prevent="newObject"
    >
      <div class="col-md-6">
        <h2>
          Kunden-Informationen
          <button
            v-if="validated"
            class="delete-cust"
            @click.prevent="deleteCust()"
          >
            <i
              class="fa fa-trash trashIcon"
              aria-hidden="true"
            ></i>
          </button>
          <button
            v-if="validated"
            class="edit-cust"
            @click.prevent="editCustomer()"
          >
            <i
              class="fas fa-edit editIcon"
              aria-hidden="true"
            ></i>
          </button>
        </h2>
        <div class="container_fluid">
          <div class="row">
            <div class="col-sm-3">
              <label>Anrede</label>
              <select
                v-model="fData.title"
                class="form-control"
                :disabled="validated"
                required
              >
                <option value="">
                  ---
                </option>
                <option value="Herr">
                  Herr
                </option>
                <option value="Frau">
                  Frau
                </option>
              </select>
            </div>
            <div class="col-sm-4">
              <label>Vorname</label>
              <input
                v-model="fData.forename"
                class="form-control"
                :disabled="validated"
                required
              />
            </div>
            <div class="col-sm-5">
              <label>Nachname</label>
              <input
                v-model="fData.surname"
                class="form-control"
                :disabled="validated"
                required
              />
            </div>
          </div>
          <h2 class="object-margin">
            Objekt-Informationen
            <button
              v-if="validated"
              class="delete-cust"
              @click.prevent="deleteProp()"
            >
              <i
                class="fa fa-trash trashIcon"
                aria-hidden="true"
              ></i>
            </button>
            <button
              v-if="validated"
              class="edit-cust"
              @click.prevent="editProp('comp')"
            >
              <i
                class="fas fa-edit editIcon"
                aria-hidden="true"
              ></i>
            </button>
          </h2>
          <div class="row">
            <div class="col-sm-8">
              <label>Straße</label>
              <input
                v-model="fData.street"
                class="form-control"
                :disabled="validated"
                required
              />
            </div>
            <div class="col-sm-4">
              <label>Haus-Nr.</label>
              <input
                v-model="fData.hsnr"
                class="form-control"
                :disabled="validated"
                required
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <label>Postleitzahl</label>
              <input
                v-model="fData.zip"
                class="form-control"
                :disabled="validated || filled"
                autocomplete="no"
                required
                @input="onZipChange"
              />
              <div
                v-if="autoCompleteZip.length > 0"
                class="autocomplete-items autoscroll"
              >
                <div
                  v-for="autoComp in autoCompleteZip"
                  :key="autoComp.loc_id"
                  @click="autoFill(autoComp)"
                >
                  {{ autoComp.PostalCode }}, {{ autoComp.City }}, {{ autoComp.State }}
                </div>
              </div>
            </div>
            <div class="col-sm-8">
              <label>Ort</label>
              <input
                v-model="fData.city"
                class="form-control"
                :disabled="validated || filled"
                autocomplete="no"
                required
                @input="onCityChange"
              />
              <div
                v-if="autoCompleteCity.length > 0"
                class="autocomplete-items autoscroll"
              >
                <div
                  v-for="autoComp in autoCompleteCity"
                  :key="autoComp.loc_id"
                  @click="autoFill(autoComp)"
                >
                  {{ autoComp.PostalCode }}, {{ autoComp.City }}, {{ autoComp.State }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-11">
              <label>Bundesland</label>
              <select
                v-model="fData.county"
                class="form-control"
                disabled
                required
              >
                <option value="">
                  Wird automatisch ausgefüllt
                </option>
                <option value="BW">
                  Baden-Württemberg
                </option>
                <option value="BY">
                  Bayern
                </option>
                <option value="BE">
                  Berlin
                </option>
                <option value="BB">
                  Brandenburg
                </option>
                <option value="HB">
                  Bremen
                </option>
                <option value="HH">
                  Hamburg
                </option>
                <option value="HE">
                  Hessen
                </option>
                <option value="MV">
                  Mecklenburg-Vorpommern
                </option>
                <option value="NI">
                  Niedersachsen
                </option>
                <option value="NW">
                  Nordrhein-Westfalen
                </option>
                <option value="RP">
                  Rheinland-Pfalz
                </option>
                <option value="SL">
                  Saarland
                </option>
                <option value="SN">
                  Sachsen
                </option>
                <option value="ST">
                  Sachsen-Anhalt
                </option>
                <option value="SH">
                  Schleswig-Holstein
                </option>
                <option value="TH">
                  Thüringen
                </option>
              </select>
            </div>
            <div class="col-sm-1">
              <button
                v-if="!pickedObject.property && filled"
                type="button"
                class="btn btn-small btn-danger undoAuto buttonColor"
                @click="undoFill"
              >
                X
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="container_fluid">
          <h2 class="special-header-1"></h2>
          <div class="row">
            <div class="col-sm-12">
              <label>{{ company.short }}-Kundennummer</label>
              <input
                v-model="fData.customer_nr"
                class="form-control"
                :disabled="validated"
              />
            </div>
          </div>
          <h2 class="special-header-2"></h2>
          <div class="row">
            <div class="col-sm-12">
              <label>{{ company.short }}-Auftragsnummer</label>
              <input
                v-model="fData.object_nr"
                class="form-control"
                :disabled="validated"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <button
                v-if="!pickedObject.property"
                type="button"
                class="btn btn-small btn-danger searchButton buttonColor"
                @click="searchCustProp"
              >
                Suche starten
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <h2 style="overflow: hidden;">
          Mitarbeiter
					<!-- <button
            class="edit-empl"
            @click.prevent="editEmployee()"
          >
            <i
              class="fas fa-edit editIcon"
              aria-hidden="true"
            ></i>
          </button> -->
          <button
            id="btnLogout"
            type="button"
            class="btn btn-small btn-danger"
            style="float:right"
            @click="logout"
          >
            Logout
          </button>
        </h2>
				<a href="#" @click.prevent="editEmployee()" style="float: right;">Passwort ändern</a>
				<br><br>
        <strong>{{ company.name }}</strong><br><br>
        {{ employee.forename }} {{ employee.surname }}<br>
        <br>
        {{ company.street }} {{ company.hsnr }}<br>
        {{ company.zip }} {{ company.city }}<br>
        E-Mail:<a :href="'mailto:' + employee.email">{{ employee.email }}</a><br>
        Support: Tel. {{ company.phone }}
				<br><br>
				<div class="text-right">
					<a
						href="SEPA-Vorlage.sig"
						target="_blank"
						class="spanLink"
					>
						SEPA-Vorlage-Lastschrifteinzug
					</a><br>
					<a
						href="BKM-Benutzerdokumentation.pdf"
						target="_blank"
						class="spanLink"
					>
						Benutzerdokumentation
					</a>

				</div>
      </div>
    </form>
    <div v-if="custFilt.length > 0">
      <button
        class="filtClose"
        @click="closeFilter"
      >
        <i
          class="fa fa-window-close closeIcon"
          aria-hidden="true"
        ></i>
      </button>
      <div
        class="object-selector"
      >
        <ul>
          <li
            v-for="prop in custFilt"
            :key="prop.property.id"
            @click="pickCustomerObject(prop)"
          >
            <a>
              <label>{{ prop.title }} {{ prop.forename }} {{ prop.surname }} {{ prop.customer_nr != ''? '( ' + prop.customer_nr + ' )' : '' }}</label>
              {{ prop.property.street }} {{ prop.property.hsnr }},
              {{ prop.property.zip }} {{ prop.property.city }}, {{ prop.property.county }} {{ prop.property.object_nr != ''? '( ' + prop.property.object_nr + ' )' : '' }}
            </a>
            <button
              class="delete-prop"
              @click.stop="deleteProp(prop)"
            >
              <i
                class="fa fa-trash trashIcon"
                aria-hidden="true"
              ></i>
            </button>
            <button
              class="edit-prop"
              @click.stop="editProp('list', prop)"
            >
              <i
                class="fas fa-edit editIcon"
                aria-hidden="true"
              ></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div
      v-if="pickedObject.property"
      class="obj-selection"
    >
      <div class="row">
        <div class="col-sm-3">
          <p>Ausgewähltes Objekt</p>
        </div>
        <div class="col-sm-7">
          <p>
            {{ pickedObject.forename }} {{ pickedObject.surname }}, {{ pickedObject.property.street }} {{ pickedObject.property.hsnr }}, {{ pickedObject.property.zip }} {{ pickedObject.property.city }}, {{ pickedObject.property.county }}
          </p>
        </div>
        <div class="col-sm-2">
          <button
            class="btn btn-small btn-danger buttonColor clearButton"
            @click="clearPickedObject"
          >
            CLR
          </button>
        </div>
      </div>
    </div>
    <DeleteCustomer
      ref="DeleteCustomer"
      :customer="pickedObject"
    ></DeleteCustomer>
    <DeleteProperty
      ref="DeleteProperty"
      :customer="customer"
    ></DeleteProperty>
    <EditCustomer
      ref="EditCustomer"
      :customer="pickedObject"
      :company="company"
    ></EditCustomer>
    <EditProperty
      ref="EditProperty"
      :customer="customer"
      :company="company"
      :origin="origin"
    ></EditProperty>
		<EditEmployee
      ref="EditEmployee"
      :company="company"
			:username="employee.username"
    ></EditEmployee>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import DeleteCustomer from './DeleteCustomer.vue'
import DeleteProperty from './DeleteProperty.vue'
import EditCustomer from './EditCustomer.vue'
import EditProperty from './EditProperty.vue'
import EditEmployee from './EditEmployee.vue'

export default {
	components: {
		DeleteCustomer,
		DeleteProperty,
		EditCustomer,
		EditProperty,
		EditEmployee
  },
  data () {
    return {
      fData: {
        title: '',
        forename: '',
        surname: '',
        street: '',
        hsnr: '',
        zip: '',
        city: '',
        county: '',
        customer_nr: '',
        object_nr: ''
      },
      sData: {
        title: [],
        forename: [],
        surname: [],
        street: [],
        hsnr: [],
        zip: [],
        city: [],
        county: [],
        customer_nr: [],
        object_nr: []
      },
      self_employee_id: '',
      company: {},
      employee: {},
      custProps: {},
      custFilt: [],
      autoCompleteZip: {},
      autoCompleteCity: {},
      auto: false,
      pickedObject: [],
      validated: false,
      createdObj: {},
			filled: false,
			customer: [],
			origin: ''
    }
  },
  computed: {
    ...mapGetters(['getCustProp']),
  },
  created () {
    const id = localStorage.getItem('picked_id')
    if(id){
      this.$axios.get(process.env.VUE_APP_BASE_URI + 'showFromProp/' + id)
        .then((response) => {
          const custProp = response.data
          this.pickCustomerObject(custProp)
        })
    }
  },

  mounted () {
    const exp = new Date(localStorage.getItem('exp'))
    const now = new Date()

    if (exp - now < 0) {
      this.logout()
    }
    this.self_employee_id = localStorage.getItem('id')
    this.$axios.get(process.env.VUE_APP_BASE_URI + 'showEmployee/' + this.self_employee_id)
      .then((response) => {
        localStorage.setItem('discr', response.data.employee.discr)
        localStorage.setItem('role_id', response.data.employee.role_id)
        this.company = response.data
        this.$store.dispatch('SAVE_COMPANY', response.data)
        this.employee = response.data.employee
      })
    this.$axios.get(process.env.VUE_APP_BASE_URI + 'employeeProps/' + localStorage.getItem('id'))
      .then((response) => {
            this.custProps = response.data
			})
	},
  methods: {
    logout() {
      this.clearPickedObject()
      localStorage.removeItem('token')
      localStorage.removeItem('id')
      localStorage.removeItem('exp')
      localStorage.removeItem('role_id')
      localStorage.removeItem('discr')
      localStorage.removeItem('picked_id')
      this.$router.push('/')
		},
		deleteProp(prop) {
			this.customer = prop? prop : this.pickedObject
			this.$refs.DeleteProperty.showDelete()
		},
		deleteCust() {
			this.$refs.DeleteCustomer.showDelete()
		},
		editProp(origin, prop) {
			this.origin = origin
			this.customer = prop? prop : this.pickedObject
			this.$refs.EditProperty.showUpdate()
		},
		editCustomer() {
			this.$refs.EditCustomer.showUpdate()
		},
		editEmployee() {
			this.$refs.EditEmployee.showUpdate()
		},
    clearPickedObject() {
      this.filled = false
      this.$store.dispatch('REMOVE_DOCS')
      this.fData.title = ''
      this.fData.forename = ''
      this.fData.surname = ''
      this.fData.customer_nr = ''
      this.fData.street = ''
      this.fData.hsnr = ''
      this.fData.zip = ''
      this.fData.county = ''
      this.fData.city = ''
      this.fData.object_nr = ''
      this.pickedObject = []
      localStorage.removeItem('picked_id')
			this.$store.dispatch('SAVE_CUSTPROP', this.pickedObject)
			this.validated = false
			this.$router.go()
    },
    pickCustomerObject(prop) {
      this.$axios.get(process.env.VUE_APP_BASE_URI + 'documentProp/' + prop.property.id)
        .then((response) => {
          this.$store.dispatch('SAVE_DOCS', response.data)
          this.$store.dispatch('SAVE_PROP_ID', prop.property.id)
          this.$store.dispatch('SAVE_AGS', prop.property.ags)
        })
      this.foundProp = []
      this.custFilt = []
      this.autoCompleteZip = {}
      this.autoCompleteCity = {}
      this.fData.title = prop.title
      this.fData.forename = prop.forename
      this.fData.surname = prop.surname
      this.fData.customer_nr = prop.customer_nr
      this.fData.street = prop.property.street
      this.fData.hsnr = prop.property.hsnr
      this.fData.zip = prop.property.zip
      this.fData.city = prop.property.city
      this.fData.county = prop.property.county
      this.fData.object_nr = prop.property.object_nr
      localStorage.setItem('picked_id', prop.property.id)
      this.pickedObject = prop
			this.$store.dispatch('SAVE_CUSTPROP', this.pickedObject)
      this.validated = true
    },
    autoFill(auto) {
      this.custFilt = []
      this.fData.zip = auto.PostalCode
      this.fData.city = auto.City
      this.fData.county = auto.State
      this.autoCompleteZip = {}
      this.autoCompleteCity = {}
      this.filled = true
    },
    undoFill() {
      this.fData.zip = ''
      this.fData.city = ''
      this.fData.county = ''
      this.filled = false
    },
    searchCustProp () {
      this.clearSearchData()
      for(var key in this.fData){
        if(key === 'county' || key === 'title'){
          continue
				}
        this.custProps.forEach(custProp => {
          if(this.fData[key] !== ''){
            custProp.forEach(cust => {
							if(cust[key] !== null) {
								if(cust[key] && cust[key] !== ''){
									if(cust[key].toLowerCase().includes(this.fData[key].toLowerCase())){
										this.sData[key] = cust.properties
									}
								} else if (!cust[key]){
									cust.properties.forEach(prop => {
										if (prop[key] !== null) {
											if(prop[key].toLowerCase().includes(this.fData[key].toLowerCase())){
												this.sData[key].push(prop)
											}
										}
									})
								}
							}
            })
          }
        })
      }
      this.intersectSearchData()
    },
    clearSearchData () {
      for(var key in this.sData){
        this.sData[key] = []
      }
    },
    intersectSearchData () {
      var result = []
      var id_list = []
      var key_id_list = []
      for(var key in this.sData){
        if(this.sData[key].length > 0){
          key_id_list = []
          this.sData[key].forEach(keyData => {
            key_id_list.push(keyData.id)
          })
          id_list.push(key_id_list)
        }
      }
      if(id_list.length === 1) {
        result = id_list[0]
      } else {
        result = this.intersection(id_list)
      }
      this.getFilteredProperties(result)
    },
    intersection (id_list) {
      var result = {}
        for (var arr in id_list) {
          for (var i = 0; i < id_list[arr].length; i++){
            var values = id_list[arr][i]
            if (result[values] === undefined) {
              result[values] = 1
            } else result[values] += 1
          }
        }
      let answer = []
        for (var key in result) {
          if (result[key] >= id_list.length) answer.push(Number(key))
        }
      return answer
    },
    getFilteredProperties (prop_list) {

      this.custFilt = []
      let eachArray = []
      let fullArray = []
      if(prop_list.length === 0){
        this.buildArray()
      } else {
        prop_list.forEach(prop_id => {
          eachArray = []
          this.$axios.get(process.env.VUE_APP_BASE_URI + 'property/' + prop_id)
            .then((response) => {
              this.$axios.get(process.env.VUE_APP_BASE_URI + 'customer/' + response.data.customer_id)
                .then((res) => {
                  eachArray = res.data
                  eachArray["property"] = response.data
                  fullArray.push(eachArray)
                  this.custFilt = fullArray
                })
            })
        })
      }
    },
    buildArray () {
      var eachArray = []
      var fullArray = []
      this.custProps.forEach(custProp => {
        custProp.forEach(cust => {
          cust.properties.forEach(prop => {
            eachArray = []
            this.$axios.get(process.env.VUE_APP_BASE_URI + 'customer/' + prop.customer_id)
              .then((response) => {
                eachArray = response.data
                eachArray["property"] = prop
                fullArray.push(eachArray)
                this.custFilt = fullArray
              })
          })
        })
      })
    },
    onCityChange() {
      if(this.fData.city.length >= 3) {
        this.$axios.get(process.env.VUE_APP_BASE_URI + 'auto/' + this.fData.city)
          .then((response) => {
            this.autoCompleteCity = response.data
            this.auto = true
          })
      } else {
        this.autoCompleteCity = {}
      }
    },
    onZipChange() {
      if(this.fData.zip.length >= 3){
        this.$axios.get(process.env.VUE_APP_BASE_URI + 'auto/' + this.fData.zip)
          .then((response) => {
            this.autoCompleteZip = response.data
            this.auto = true
          })
      } else {
        this.autoCompleteZip = {}
      }
    },
    closeFilter () {
      this.custFilt = []
    },
    newObject() {
    this.custFilt = []
      this.$axios.post(process.env.VUE_APP_BASE_URI + 'property/create', {
        title: this.fData.title,
        forename: this.fData.forename,
        surname: this.fData.surname,
        customer_nr: this.fData.customer_nr,
        street: this.fData.street,
        hsnr: this.fData.hsnr,
        zip: this.fData.zip,
        city: this.fData.city,
        county: this.fData.county,
        object_nr: this.fData.object_nr
      })
        .then((response) => {
					this.createdObj = response.data.data
          this.pickCustomerObject(response.data.data)
				})
		}
  }
}
</script>

<style>
.noPaddingR {
  padding-right: 0px !important;
}
.closeIcon{
  color: white;
}
.trashIcon {
	color: #d43f3a;
}
.editIcon {
	color: #5a5a5a;
}
.object-margin {
	margin-top: 10px !important;
}
.delete-prop {
	line-height: 24px;
	position: relative;
	float: right;
	width: 30px;
	height: 28px;
	margin-right: 10px;
	font-size: small;
}
.edit-prop {
	line-height: 24px;
	position: relative;
	float: right;
	width: 30px;
	height: 28px;
	margin-right: 5px;
	font-size: small;
}
.delete-cust {
	line-height: 24px;
	position: relative;
	float: right;
	width: 30px;
	height: 28px;
	font-size: small;
}
.edit-cust {
	line-height: 24px;
	position: relative;
	float: right;
	width: 30px;
	height: 28px;
	margin-right: 5px;
	font-size: small;
}
.edit-empl {
	line-height: 24px;
	position: relative;
	float: right;
	width: 30px;
	height: 28px;
	margin-right: 5px;
	font-size: small;
}
.special-header-1 {
	margin-top: 34px !important;
}
.special-header-2 {
	margin-top: 42px !important;
}
</style>
