<template>
  <transition
    v-if="showModal"
    name="modal-fade"
  >
    <div class="modal-backdrop">
      <div
        id="delModal"
        class="modal delModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                id="exampleModalLabel"
                class="modal-title"
              >
                Dokument entfernen
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="delModal"
                aria-label="Close"
                @click="hideModal()"
              >
                <span
                  class="x-position"
                  aria-hidden="true"
                >&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Sind Sie sicher, dass Sie das Dokument löschen wollen?</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="delModal"
                aria-label="Close"
                @click="hideModal()"
              >
                Nein
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="deleteFile()"
              >
                Löschen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    document: {
			type: Object,
			required: true
		}
  },
  data () {
    return {
      showModal: false
    }
  },
  methods: {
    deleteFile () {
      this.$axios.delete(process.env.VUE_APP_BASE_URI + 'document/docDelete/' + this.document.id)
        .then(() => {
          this.$router.go();
        })
    },
    showDelete () {
      this.showModal = true
      let element = document.getElementById("delModal")
      $(element).modal('show')
    },
    hideModal () {
      this.showModal = false
      let element = document.getElementById("delModal")
      $(element).modal('hide')
    }
  }
}
</script>
<style>
.delModal {
  height: 284px;
  text-align: center;
}
.modal-position {
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
}
.modal {
  padding-top: 0;
}
.x-position {
  position: relative;
  top: -11px;
  right: 6px;
}
</style>

<style scoped>
	.modal-content {
		height: unset !important;
	}
	.delModal {
		height: 284px !important;
	}
	.modal-body {
		height: unset !important;
	}
</style>

