<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
export default {

	data () {
		return {

		}
	}
}
</script>

<style>
@import './assets/css/main.css';
@import './assets/css/theme-bkm.css';
</style>
