<template>
  <transition
    v-if="showModal"
    name="modal-fade"
  >
    <div class="modal-backdrop">
      <div
        class="modal"
        :class="{ fullModal: maxed }"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >

        <header class="modal-bar">
          <button
            type="button"
            class="minMaxPdf"
            @click="minMax"
          >
            <i
              v-if="!maxed"
              class="pdfBtn far fa-window-maximize"
            ></i>
            <i
              v-if="maxed"
              class="pdfBtn fas fa-window-minimize"
            ></i>
          </button>
          <button
            type="button"
            class="closePdf"
            data-dismiss="modal"
            aria-label="Close"
            @click="hide"
          >
            <i class="fas fa-times"></i>
          </button>
        </header>
        <!-- <iframe	height="100%" width="100%" :src="url"></iframe> -->
        <iframe
          height="100%"
          width="100%"
          :src="url"
        ></iframe>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data () {
    return {
			showModal: false,
      url: '',
      maxed: false
    }
  },
  methods: {
    hide () {
      this.maxed = false
      this.showModal = false
		},
    showPdf (url, id) {
      var baseUrl = process.env.VUE_APP_BASE_URI
      var viewer = 'web/viewer.html?file=';
      var data = {}
      if (url !== '') {
        data = {
          doc_id: id,
          path: url
        }
      }
      this.$axios({
        method: 'post',
        url: baseUrl + 'document/download',
        responseType: 'blob',
        data: data
      })
        .then((response) => {
          var blob = new Blob([response.data], { type: 'application/pdf' })
          this.url = viewer + window.URL.createObjectURL(blob)// + frameProps
          this.showModal = true
        })
    },
    minMax () {
      this.maxed = !this.maxed
    }
  }
}
</script>
<style scoped>
.fullModal {
    width: 98%;
    height: 100%;
}
</style>
