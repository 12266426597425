<template>
	<div class="home">
		<div class="wrapper logout">
			<div class="logo-home"></div>
		</div>
		<div class="wrapper">
			<div class="content">
				<div class="alert alert-danger">
					<h2 class="alert-heading">Wichtige Information</h2>
					<p>Dieses Projekt wurde von GSD360 abgelöst. Sie können hier lediglich noch ihre besetehenden Dokumente
						herunterladen.</p>
					<p>Sie können sich dort mit den gleichen Zugangsdaten einloggen.</p>
					<a class="btn btn-primary" href="https://app.gsd360.de/">Zum GSD360-Login</a>
				</div>
				<div class="pre-header">
          <span class="custom-id">
            <strong>Dokumenten-Bestellung</strong>
          </span>
				</div>
				<Property></Property>
				<DocSelect></DocSelect>
				<DocCard></DocCard>
			</div>
		</div>
	</div>
</template>

<script>
import Property from '../components/PropertyComp.vue'
import DocCard from '../components/DocCard.vue'
import DocSelect from "../components/DocSelectForm";

export default {
	components: {
		DocSelect,
		Property,
		DocCard
	},
	data() {
		return {}
	}
}
</script>
