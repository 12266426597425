import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login.vue'
import Home from './views/Home.vue'
import Admin from './views/Admin.vue'
import axios from 'axios'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/home',
      name: 'home',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: Home,
      beforeEnter: (to, from, next) => {
        var id = localStorage.getItem('id')
        if (!id) {
          next(from)
        }
        axios.get(process.env.VUE_APP_BASE_URI + 'getRoleID/' + id)
          .then((response) => {
            if (response.data === '3' || response.data === '4') {
              next()
            } else {
              next(from)
            }
          })
      }
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin,
      beforeEnter: (to, from, next) => {
        // ! remove id 1
        var id = localStorage.getItem('id')
        if (!id) {
          next(from)
        }
        axios.get(process.env.VUE_APP_BASE_URI + 'getRoleID/' + id)
          .then((response) => {
            if (response.data === '2' || response.data === '1') {
              next()
            } else {
              next(from)
            }
          })
      }
    }
  ]
})
