<template>
  <div id="employee">
    <form
      class="row header"
      @submit.prevent="sendForm"
    >
      <div class="col-md-7">
        <h2 v-if="empType === 'internal'">
          Innendienst-Verwaltung
        </h2>
        <h2 v-if="empType === 'external'">
          Außendienst-Verwaltung
        </h2>
        <div class="container_fluid">
          <div class="row">
            <div class="col-sm-4">
              <label>Anrede</label>
              <select
                v-model="formData.title"
                class="form-control"
                required
              >
                <option value="">
                  ---
                </option>
                <option value="Herr">
                  Herr
                </option>
                <option value="Frau">
                  Frau
                </option>
              </select>
            </div>
            <div class="col-sm-4">
              <label>Vorname</label>
              <input
                v-model="formData.forename"
                type="text"
                class="form-control"
                required
              />
            </div>
            <div class="col-sm-4">
              <label>Nachname</label>
              <input
                v-model="formData.surname"
                type="text"
                class="form-control"
                required
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3">
              <label>Benutzername</label>
              <input
                v-model="formData.username"
                type="text"
                class="form-control"
                :disabled="picked"
                required
              />
            </div>
            <div class="col-sm-5">
              <label>E-Mail</label>
              <input
                v-model="formData.email"
                type="text"
                class="form-control"
                required
              />
            </div>
            <div class="col-sm-4">
              <label>Passwort</label>
              <input
                v-model="formData.password"
                type="text"
                class="form-control"
                :required="!picked"
              />
            </div>
          </div>
          <div class="row">
            <button
              v-if="!picked"
              style="margin-top: 30px;"
              type="submit"
              class="btn btn-small btn-danger"
            >
              Nutzer anlegen
            </button>
            <button
              v-if="picked"
              style="margin-top: 30px;"
              type="submit"
              class="btn btn-small btn-danger"
            >
              Nutzer bearbeiten
            </button>
            <button
              v-if="picked"
              style="margin-top: 30px;"
              type="button"
              class="btn btn-small btn-danger"
              @click="deleteEmployee"
            >
              Nutzer löschen
            </button>
            <p>{{ error }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <h2 style="overflow: hidden">
          Admin-Info
          <button
            id="btnLogout"
            class="btn btn-small btn-danger"
            type="button"
            style="float:right"
            @click="logout"
          >
            Logout
          </button>
        </h2>
				<strong>{{ employee.forename }} {{ employee.surname }}</strong><br>
				Username: {{employee.username}}<br>
				E-Mail:<a :href="employee.email">{{ employee.email }}</a><br>
				<br>
				<strong>{{ company.name }}</strong><br>
				{{ company.street }} {{ company.hsnr }}<br>
				{{ company.zip }} {{ company.city }}<br>
        <p>
          <span class="btn-group btn-group-toggle float-right">
            <label
              class="btn btn-secondary"
              :class="{ active: empType == 'internal'}"
            >
              <input
                id="innen"
                v-model="empType"
                type="radio"
                value="internal"
                checked
              > Innendienst
            </label>
            <label
              class="btn btn-secondary"
              :class="{ active: empType == 'external'}"
            >
              <input
                id="außen"
                v-model="empType"
                type="radio"
                value="external"
                checked
              > Außendienst
            </label>
          </span>
        </p>
      </div>
    </form>
    <div
      v-if="pickedEmployee.id"
      class="obj-selection"
    >
      <div class="row">
        <div class="col-sm-3">
          <p>Ausgewählter Nutzer</p>
        </div>
        <div class="col-sm-7">
          <p>
            {{ pickedEmployee.title }} {{ pickedEmployee.forename }} {{ pickedEmployee.surname }}
          </p>
        </div>
        <div class="col-sm-2">
          <button
            class="btn btn-small btn-danger buttonColor clearButton"
            @click="clearEmployee"
          >
            CLR
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      intOrExt: false,
			self_employee_id: '',
			employee: {},
			company: {},
      formData: {
        title: '',
        forename: '',
        surname: '',
        username: '',
        email: '',
        password: ''
      },
      empType: '',
      picked: false,
      pickedEmployee: {},
      error: ''
    }
	},
	computed: {
    ...mapGetters(['getPemp'])
  },
  watch: {
    empType (newValue) {
      this.$store.dispatch('SAVE_EMP_TYPE', newValue)
      this.clearForm()
    },
    getPemp (newValue) {
      this.picked = false
      this.pickedEmployee = newValue
      for(var prop in newValue) {
        if(newValue.hasOwnProperty(prop)){
          this.fillForm()
        }
      }
    }
  },
  mounted () {
    this.empType = 'internal'
    this.$store.dispatch('SAVE_EMP_TYPE', 'internal')
		this.self_employee_id = localStorage.getItem('id')
		this.$axios.get(process.env.VUE_APP_BASE_URI + 'showEmployee/' + this.self_employee_id)
			.then((response) => {
				this.company = response.data
				this.employee = response.data.employee
			})
  },
  methods: {
    fillForm () {
      this.error = ''
      this.picked = true
      for(var key in this.formData){
        this.formData[key] = this.pickedEmployee[key]
      }
    },
    clearForm () {
      for(var key in this.formData) {
        this.formData[key] = ''
      }
    },
    logout () {
      localStorage.removeItem('token')
      localStorage.removeItem('id')
      localStorage.removeItem('exp')
      // localStorage.removeItem('role_id')
      // localStorage.removeItem('discr')
      // localStorage.removeItem('picked_id')
      this.$router.push('/')
    },
    clearEmployee () {
      this.error = ''
      this.picked = false
      this.$store.dispatch('SAVE_PICKED_EMP', {})
      this.clearForm()
    },
    sendForm () {
      if(!this.picked) {
        this.newEmployee()
      } else {
        this.updateEmployee()
      }
    },
    newEmployee () {
      var uri = ''
      if (this.empType === 'internal') {
        uri = 'admin/register'
      } else if (this.empType === 'external') {
        uri = 'employee/create'
      }
      this.$axios.post(process.env.VUE_APP_BASE_URI + uri, {
        username: this.formData.username,
        email: this.formData.email,
        password: this.formData.password,
        title: this.formData.title,
        forename: this.formData.forename,
        surname: this.formData.surname
      })
        .then(() => {
          this.$router.go()
        })
    },
    updateEmployee () {
      this.error = ''
      var uri = ''
      if (this.empType === 'internal') {
        uri = 'admin/updateInternal/'
      } else if (this.empType === 'external') {
        uri = 'employee/update/'
      }
      this.$axios.post(process.env.VUE_APP_BASE_URI + uri + this.pickedEmployee.id, {
        email: this.formData.email,
        password: this.formData.password,
        title: this.formData.title,
        forename: this.formData.forename,
        surname: this.formData.surname
      })
        .then(() => {
          this.$router.go()
        })
    },
    deleteEmployee () {
      var uri = ''
      if(this.empType === 'internal') {
        uri = 'admin/delInternal/'
      } else if (this.empType === 'external') {
        uri = 'employee/delete/'
      }
      this.$axios.delete(process.env.VUE_APP_BASE_URI + uri + this.pickedEmployee.id)
        .then(() => {
          this.$router.go()
        })
        .catch(() => {
          this.error = 'Der Ausgangsnutzer kann nicht entfernt werden.'
        })
    }
  }
}
</script>
<style>

</style>
