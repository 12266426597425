<template>
  <div class="doc-card-container">
    <div class="row card-row">
      <div
        v-for="propDoc in availableDocs"
        :key="propDoc.id"
        class="doc-card col"
      >
        <h3>
          {{ propDoc.doc_type }}
        </h3>
        <div class="doc-actionplane">
          <button
            class="btn btn-danger action-zoom"
            type="button"
            title="Vorschau"
            @click="showDoc(propDoc.path, propDoc.id)"
          >
            <i class="fas fa-tv"></i>
          </button>
          <div
            v-if="propDoc.path !== null"
            class="vspacer"
          ></div>
          <button
            v-if="propDoc.path !== null"
            class="btn btn-default action-upload"
            type="button"
            title="Download"
            @click="downloadPdf(propDoc.path, propDoc.doc_type, propDoc.id)"
          >
            <i class="fa fa-download"></i>
          </button>
          <div
            v-if="propDoc.available"
            class="vspacer"
          ></div>
          <button
            v-if="propDoc.path !== null"
            class="btn btn-default action-upload"
            type="button"
            title="Download"
            @click="openDeleteModal(propDoc)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </div>
        <canvas
          :id="propDoc.code"
          width="297"
          height="420"
          style="border:1px solid #000000;"
        >
        </canvas>
        <div class="doc-status">
          <div
            v-if="!propDoc.available"
            class="status-order"
          >
            <span class="status-desc">bestellt am: </span>
            <span class="status-value">{{ propDoc.created_at }}</span>
          </div>
          <div
            v-if="propDoc.available"
            class="status-order"
          >
            <span class="status-desc">hochgeladen am: </span>
            <span class="status-value"> {{ propDoc.created_at }}</span>
          </div>
          <div
            v-if="!propDoc.available"
            class="status-delivered"
          >
            <span class="status-desc">geliefert am: </span>
            <span
              v-if="propDoc.delivered_at !== null"
              class="status-value"
            >{{ propDoc.delivered_at }}</span>
            <span
              v-if="propDoc.delivered_at === null"
              class="status-value"
            >wird bald geliefert</span>
          </div>
        </div>
      </div>
      <PdfModal ref="PdfModal"></PdfModal>
      <DeleteModal
        ref="DeleteModal"
        :document="document"
      ></DeleteModal>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PdfModal from './PdfModal.vue'
import DeleteModal from './DeleteModal.vue'
export default {
  components: {
    PdfModal,
    DeleteModal
  },
  data () {
    return {
      url: '',
      document: {}
    }
	},
	computed: {
    ...mapGetters(['getPropDocs', 'getDocTypes']),
    availableDocs () {
      const propDocs = this.getPropDocs
      const docTypes = this.getDocTypes
      var docs = []
      propDocs.forEach(doc => {
        docTypes.forEach(type => {
          if(type.id === doc.type_id){
            doc['code'] = type.code
            doc['doc_type'] = type.doc_type
            docs.push(doc)
          }
        })
      })
      return docs
    }
  },
  watch: {
    availableDocs(newValue){
      if(newValue.length > 0){
        newValue.forEach(doc => {
          if(doc.path !== null){
            this.loadPdf(doc.path, doc.code, doc.id)
          } else {
            this.loadPdf('files/placeholder.pdf', doc.code)
          }
        })
      }
    }
  },
  mounted () {
  },
  methods: {
    openDeleteModal (propDoc) {
      this.document = propDoc
      this.$refs.DeleteModal.showDelete()
    },
    loadPdf(url, code, id) {
      if(url !== 'files/placeholder.pdf'){
        var data = {
          doc_id: id,
          path: url
        }
      }

      this.$axios({
        method: 'post',
        url: process.env.VUE_APP_BASE_URI +'document/download',
        data: data,
        responseType: 'arraybuffer'
      })
        .then((response) => {
          var loadingTask = this.$pdfjs.getDocument({ data: response.data });
          loadingTask.promise.then(pdf => {
          // Fetch the first page
            pdf.getPage(1).then(page => {
              var scale = 0.5;
              var viewport = page.getViewport({ scale: scale });
              // Prepare canvas using PDF page dimensions
              var canvas = document.getElementById(code);
              var context = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              // Render PDF page into canvas context
              var renderContext = {
                canvasContext: context,
                viewport: viewport,
              };
							page.render(renderContext);
            });
          });

        })
    },
	downloadPdf(url, name, id) {
      var fileName = name += '.pdf'
			this.$axios({
        method: 'post',
        url: process.env.VUE_APP_BASE_URI + 'document/download',
        responseType: 'blob',
        data: {
          doc_id: id,
          path: url
        }
      })
      .then(({ data }) => {
        let blob = new Blob([data], { type: 'application/pdf' })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', fileName);
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        // ! Remove link
      })
	},
    showDoc(url, id) {
			if(url !== null){
				this.$refs.PdfModal.showPdf(url, id)
			} else {
				this.$refs.PdfModal.showPdf('', '')
			}
    }
  }
}
</script>
