<template>
  <transition v-if="showModal" name="modal-fade">
    <div class="modal-backdrop">
      <div
        id="changePasswordModal"
        class="modal update-empl"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">Passwort ändern</h5>
              <button
                type="button"
                class="close"
                data-dismiss="update-empl"
                aria-label="Close"
                @click="hideModal()"
              >
                <span class="x-position" aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="changePassword()">
              <div class="modal-body">
                <div class="row">
                  <div class="col-sm-6">
                    <div>
                      <label>Altes Passwort</label>
                      <input v-model="pw.old" class="form-control" required />
                    </div>
                    <div>
                      <label>Neues Passwort</label>
                      <input v-model="pw.new" class="form-control" required />
                    </div>
                    <div>
                      <label>Neues Passwort wiederholen</label>
                      <input v-model="pw.newCheck" class="form-control" required />
                    </div>
                  </div>
                  <div v-if="error || serverError || differError" class="col-sm-6 text-danger">
                    <p v-if="error">Passwörter stimmen nicht überein</p>
                    <p v-if="serverError">Altes Passwort falsch</p>
                    <p v-if="differError">Das neue Passwort darf nicht mit dem alten übereinstimmen</p>
                  </div>
                </div>
                <!-- <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="update-empl"
                  aria-label="Close"
                  @click="hideModal()"
                >Abbrechen</button>
                <button type="submit" class="btn btn-primary">Passwort ändern</button> -->
              </div>
              <div class="modal-footer">
                <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="update-empl"
                aria-label="Close"
                @click="hideModal()"
              >Abbrechen</button>
                <button type="submit" class="btn btn-primary">Passwort ändern</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: ["company", "username"],
  data() {
    return {
      showModal: false,
      pw: {
        old: "",
        new: "",
        newCheck: "",
      },
      error: false,
      errorServer: false,
      differError: false,
    };
  },
  methods: {
    // changePassword () {
    // 	this.$axios.put(process.env.VUE_APP_BASE_URI + 'customer/' + this.customer.id, {
    // 		title: this.customer.title,
    // 		forename: this.customer.forename,
    // 		surname: this.customer.surname,
    // 		customer_nr: this.customer.customer_nr
    // 	})
    // 		.then(() => {
    // 			this.hideModal();
    // 			this.$router.go();
    // 		})
    // },
    changePassword() {
      this.error = false;
      this.errorServer = false;
      this.differError = false;

      if (this.pw.old == this.pw.new) {
        this.differError = true;
        return;
      }

      if (this.pw.new != this.pw.newCheck) {
        this.error = true;
        return;
      } else {
        this.$axios
          .put(process.env.VUE_APP_BASE_URI + "employee/password/change", {
						username: this.username,
            old_password: this.pw.old,
            new_password: this.pw.new,
          })
          .then(() => {
            this.hideModal();
            this.$router.go();
          })
          .catch(() => {
            this.errorServer = true;
          });
      }

      // 	this.$axios.put(process.env.VUE_APP_BASE_URI + 'customer/' + this.customer.id, {
      // 	old_password: this.pw.old,
      // 	new_password: this.pw.new
      // })
      // 	.then(() => {
      // 		this.hideModal();
      // 		this.$router.go();
      // 	})
      // 	.catch(() => {
      // 			this.errorServer = true
      // 	})
      // }
    },
    showUpdate() {
      this.showModal = true;
      let element = document.getElementById("update-empl");
      $(element).modal("show");
    },
    hideModal() {
      this.showModal = false;
      let element = document.getElementById("update-empl");
      $(element).modal("hide");
    },
  },
};
</script>
<style scoped>
.modal-dialog {
  height: 320px !important;
}
.modal-position {
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
}
.modal {
  padding-top: 0;
  height: 420px !important;
}
.x-position {
  position: relative;
  top: -11px;
  right: 6px;
}
.modal-content {
	border: none !important;
}
</style>
