<template>
  <transition
    v-if="showModal"
    name="modal-fade"
  >
    <div class="modal-backdrop">
      <div
        id="updatePropertyModal"
        class="modal update-prop"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                id="exampleModalLabel"
                class="modal-title"
              >
                Objekt bearbeiten
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="update-prop"
                aria-label="Close"
                @click="hideModal()"
              >
                <span
                  class="x-position"
                  aria-hidden="true"
                >&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form
                @submit.prevent="updateProperty()"
              >
                <div class="row">
                  <div class="col-sm-8">
                    <label>Straße</label>
                    <input
                      v-model="customer.property.street"
                      class="form-control"
                      required
                    />
                  </div>
                  <div class="col-sm-4">
                    <label>Haus-Nr.</label>
                    <input
                      v-model="customer.property.hsnr"
                      class="form-control"
                      required
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <label>Postleitzahl</label>
                    <input
                      v-model="customer.property.zip"
                      class="form-control"
                      autocomplete="no"
                      required
                      @input="onZipChange"
                    />
                    <div
                      v-if="autoCompleteZip.length > 0"
                      class="autocomplete-items autoscroll"
                    >
                      <div
                        v-for="autoComp in autoCompleteZip"
                        :key="autoComp.loc_id"
                        @click="autoFill(autoComp)"
                      >
                        {{ autoComp.PostalCode }}, {{ autoComp.City }}, {{ autoComp.State }}
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-8">
                    <label>Ort</label>
                    <input
                      v-model="customer.property.city"
                      class="form-control"
                      autocomplete="no"
                      required
                      @input="onCityChange"
                    />
                    <div
                      v-if="autoCompleteCity.length > 0"
                      class="autocomplete-items autoscroll"
                    >
                      <div
                        v-for="autoComp in autoCompleteCity"
                        :key="autoComp.loc_id"
                        @click="autoFill(autoComp)"
                      >
                        {{ autoComp.PostalCode }}, {{ autoComp.City }}, {{ autoComp.State }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-7">
                    <label>Bundesland</label>
                    <select
                      v-model="customer.property.county"
                      class="form-control"
                      disabled
                      required
                    >
                      <option value="">
                        Wird automatisch ausgefüllt
                      </option>
                      <option value="BW">
                        Baden-Württemberg
                      </option>
                      <option value="BY">
                        Bayern
                      </option>
                      <option value="BE">
                        Berlin
                      </option>
                      <option value="BB">
                        Brandenburg
                      </option>
                      <option value="HB">
                        Bremen
                      </option>
                      <option value="HH">
                        Hamburg
                      </option>
                      <option value="HE">
                        Hessen
                      </option>
                      <option value="MV">
                        Mecklenburg-Vorpommern
                      </option>
                      <option value="NI">
                        Niedersachsen
                      </option>
                      <option value="NW">
                        Nordrhein-Westfalen
                      </option>
                      <option value="RP">
                        Rheinland-Pfalz
                      </option>
                      <option value="SL">
                        Saarland
                      </option>
                      <option value="SN">
                        Sachsen
                      </option>
                      <option value="ST">
                        Sachsen-Anhalt
                      </option>
                      <option value="SH">
                        Schleswig-Holstein
                      </option>
                      <option value="TH">
                        Thüringen
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-5">
                    <label>{{ company.short }}-Auftragsnummer</label>
                    <input
                      v-model="customer.property.object_nr"
                      class="form-control"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="update-prop"
                aria-label="Close"
                @click="hideModal()"
              >
                Abbrechen
              </button>
              <button
                type="button"
                class="btn btn-primary"
                :disabled="!checked"
                @click="updateProperty()"
              >
                Aktualisieren
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: ['customer', 'company', 'origin'],
  data () {
    return {
			showModal: false,
			autoCompleteZip: {},
			autoCompleteCity: {},
			filled: false,
			checked: true
    }
	},
  methods: {
		updateProperty () {
			this.$axios.put(process.env.VUE_APP_BASE_URI + 'property/' + this.customer.property.id, {
				street: this.customer.property.street,
				hsnr: this.customer.property.hsnr,
				zip: this.customer.property.zip,
				city: this.customer.property.city,
				county: this.customer.property.county,
				object_nr: this.customer.property.object_nr
			})
				.then(() => {
					this.hideModal();
					if ( this.origin == 'comp' ) {
						this.$router.go();
						this.$parent.pickCustomerObject(this.customer)
					}
				})
		},
    showUpdate () {
      this.showModal = true
      let element = document.getElementById("update-prop")
      $(element).modal('show')
    },
    hideModal () {
      this.showModal = false
      let element = document.getElementById("update-prop")
      $(element).modal('hide')
		},
		onCityChange() {
			this.checked = false
      if(this.customer.property.city.length >= 3) {
        this.$axios.get(process.env.VUE_APP_BASE_URI + 'auto/' + this.customer.property.city)
          .then((response) => {
            this.autoCompleteCity = response.data
            this.auto = true
          })
      } else {
        this.autoCompleteCity = {}
      }
    },
    onZipChange() {
			this.checked = false
      if(this.customer.property.zip.length >= 3){
        this.$axios.get(process.env.VUE_APP_BASE_URI + 'auto/' + this.customer.property.zip)
          .then((response) => {
            this.autoCompleteZip = response.data
            this.auto = true
          })
      } else {
        this.autoCompleteZip = {}
      }
		},
		autoFill(auto) {
      this.custFilt = []
      this.customer.property.zip = auto.PostalCode
      this.customer.property.city = auto.City
      this.customer.property.county = auto.State
      this.autoCompleteZip = {}
      this.autoCompleteCity = {}
			this.filled = true
			this.checked = true
    },
	}
}
</script>
<style scoped>
.modal-dialog {
	height: 375px !important;
}
.modal-position {
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
}
.modal {
	padding-top: 0;
	height: 431px !important;
}
.x-position {
  position: relative;
  top: -11px;
  right: 6px;
}
</style>
