<template>
	<div id="docselect">
		<form>
			<div class="row">
				<div
					v-for="document in compDocument"
					:key="document.id"
					class="col-xl-4 col-md-6 col-sm-12"
				>
					<div class="row">
						<div class="col-9">
							<div class="form-check">
								<label class="col-select form-check-label">
									<input
										v-model="document.checked"
										class="form-check-input"
										type="checkbox"
										:disabled="true"
									/>
									{{ document.doc_type }}
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
	components: {

	},
	data () {
		return {
			documents: {},
			all: false,
			conditions: {},
			dataLoaded: false,
			compDocument: [],
			objStatus: false,
			orderList: [],
			currentProperty: {},
			someChecked: true,
			currentDocument: {}
		}
	},
	computed: {
		...mapGetters([
			'getPropDocs',
			'getObjStatus',
			'getPropertyID'
		]),
		orderTitle() {
			if(this.objStatus) {
				return ''
			} else {
				return 'Wählen Sie ein Objekt aus, um eine Bestellung abgeben zu können.'
			}
		}
	},
	watch: {
		getPropDocs(){
			this.checkAccess();
		},
		getObjStatus(newValue){
			this.objStatus = newValue
		},
		getPropertyID(newValue){
			if(newValue === ''){
				this.currentProperty = {}
			} else {
				this.$axios.get(process.env.VUE_APP_BASE_URI + 'property/' + newValue)
					.then((response) => {
						this.currentProperty = response.data
					})
			}
		}
	},
	mounted () {
		// GET ALL DOC TYPES
		this.$axios.get(process.env.VUE_APP_BASE_URI + 'doctypes')
			.then((response) => {
				this.documents = response.data
				this.$store.dispatch('SAVE_DOCTYPES', response.data)
			})
		this.self_employee_id = localStorage.getItem('id')
		this.$axios.get(process.env.VUE_APP_BASE_URI + 'getRoleID/' + this.self_employee_id)
			.then((response) => {
				this.$axios.get(process.env.VUE_APP_BASE_URI + 'getRole/' + response.data)
					.then((response) => {
						this.conditions = response.data
						this.dataLoaded = true
						this.checkAccess()
					})
			})
	},
	methods: {
		checkAccess() {
			var allowed = []
			if(this.conditions.length > 0 && this.documents.length > 0) {
				this.documents.forEach(document => {
					this.conditions.forEach(condition => {
						if (document.short === condition) {
							document.checked = false
							document.ordered = false
							this.getPropDocs.forEach(doc => {
								if(document.id === doc.type_id){
									document.ordered = true
									document.checked = true
								}
							})
							allowed.push(document)
						}
					})
				})
			}
			this.compDocument = allowed
		},
		getProperty() {
			this.$axios.get(process.env.VUE_APP_BASE_URI + 'property/' + this.$store.getters['getPropertyID'])
				.then((response) => {
					this.currentProperty = response.data
				})
		}
	}
}
</script>
<style scoped>
.document-selection label {
	font-weight: 400;
}
.upBtn i {
	margin: 0;
}
</style>
