<template>
  <transition
    v-if="showModal"
    name="modal-fade"
  >
    <div class="modal-backdrop">
      <div
        id="delPropModal"
        class="modal delPropModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                id="exampleModalLabel"
                class="modal-title"
              >
                Objekt löschen
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="delPropModal"
                aria-label="Close"
                @click="hideModal()"
              >
                <span
                  class="x-position"
                  aria-hidden="true"
                >&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Sind Sie sicher, dass Sie folgendes Objekt</p>
              <p><strong>{{ customer.property.street }} {{ customer.property.hsnr }}</strong></p>
              <p><strong>{{ customer.property.zip }} {{ customer.property.city }}, {{ customer.property.county }}</strong></p>
              <p v-if="customer.property.object_nr">
                <strong>( {{ customer.property.object_nr }} )</strong>
              </p>
              <p>und alle dazugehörigen Dokumente und unwiderruflich löschen wollen?</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="delPropModal"
                aria-label="Close"
                @click="hideModal()"
              >
                Nein
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="deleteProperty()"
              >
                Löschen
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: ['customer'],
  data () {
    return {
      showModal: false
    }
  },
  methods: {
		deleteProperty () {
			this.$axios.delete(process.env.VUE_APP_BASE_URI + 'property/' + this.customer.property.id )
				.then(() => {
					this.hideModal();
					this.$parent.clearPickedObject();
				})
		},
    showDelete () {
      this.showModal = true
      let element = document.getElementById("delPropModal")
      $(element).modal('show')
    },
    hideModal () {
      this.showModal = false
      let element = document.getElementById("delPropModal")
      $(element).modal('hide')
    }
  }
}
</script>
<style scoped>
.modal-dialog {
	height: 400px !important;
}
.modal-position {
  left: 50%;
  top: 80%;
  transform: translate(-50%, -50%);
}
.modal {
	padding-top: 0;
	height: 456px !important;
}
.x-position {
  position: relative;
  top: -11px;
  right: 6px;
}
</style>
