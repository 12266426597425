<template>
  <div id="admin">
    <div class="wrapper logout">
      <div class="logo"></div>
    </div>
    <div class="wrapper">
      <div class="content">
        <div class="pre-header">
          <span class="custom-id">
            <strong>Admin-Verwaltung</strong>
          </span>
        </div>
        <Admin></Admin>
        <Employee></Employee>
      </div>
    </div>
  </div>
</template>
<script>
import Admin from '../components/AdminPanel.vue'
import Employee from '../components/EmployeeSelect.vue'

export default {
  components: {
    Admin,
    Employee
  },
  data () {
    return {
    }
  },
  mounted () {
		//
  },
  methods: {
    //
  }

}
</script>
<style>

</style>
